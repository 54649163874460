<template>
  <DisplayState />
  <hr />
  <ChangeState />
  <hr />
  <DisplayState />
</template>

<script>
import DisplayState from './DisplayState.vue';
import ChangeState from './ChangeState.vue';

export default {
  name: 'VuexDemo',
  components: {
    DisplayState,
    ChangeState,
  },
};
</script>
