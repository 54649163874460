<template>
  <div class="playlistCategory">
    <div class="topbar">
      <div class="title">
        <h2>{{ title }}</h2>
      </div>
      <SearchBar v-model:query="query" :placeholder="title" />
    </div>
    <CardRow>
      <Card
        v-for="cardItem in sortedPlaylists"
        v-bind="cardItem"
        :desc="cardItem.creator"
        :hasPlayButton="true"
        :key="cardItem.id"
        @click="onPlaylistCardClicked(cardItem.id)"
      />
    </CardRow>
  </div>
</template>

<script>
import CardRow from '@/components/CardRow.vue';
import Card from '@/components/Card.vue';
import SearchBar from '@/components/SearchBar/SearchBar';

export default {
  name: 'Category',
  components: {
    SearchBar,
    CardRow,
    Card,
  },
  data() {
    return {
      query: '',
      sortedPlaylists: this.playlists,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    playlists: {
      type: Array,
    },
  },
  methods: {
    onPlaylistCardClicked(id) {
      this.$router.push({
        name: 'Playlist view',
        params: {
          id,
        },
      });
    },
  },
  watch: {
    query(val) {
      this.sortedPlaylists = this.playlists;
      if (val) {
        this.sortedPlaylists = this.playlists.filter((obj) => {
          let objTitle = obj['title'].toLowerCase();
          let searchItem = val.toLowerCase();
          return objTitle.includes(searchItem.trim());
        });
        console.log(this.sortedPlaylists);
      }
    },
  },
};
</script>

<style scoped>
.playlistCategory {
  display: flex;
  flex-direction: column;
}

.topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
