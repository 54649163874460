export const timeToSeconds = (time = '0:00') => {
  const splitTime = time.split(':');
  if (splitTime.length < 2 || splitTime.length > 3) {
    return 0;
  }
  return splitTime.reverse().reduce((acc, nextTime, currentIndex) => {
    return (acc += parseInt(nextTime, 10) * Math.pow(60, currentIndex));
  }, 0);
};

export const percentageDiffOfTimesInSecs = (time1InSecs, time2InSecs) => {
  if (!time2InSecs) return 0;
  return (time1InSecs / time2InSecs) * 100;
};

export const secsToTimestamp = (secs) => {
  const minutes = Math.floor(secs / 60);
  const seconds = String(secs % 60).padStart(2, '0');
  return `${minutes}:${seconds}`;
};
