<template>
  <div>
    Playing? {{ isPlaying }}
    <br />
    Track: {{ currentTrack }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'VuexDemo',
  computed: {
    ...mapState({
      isPlaying: (state) => state.isPlaying,
      currentTrack: (state) => state.currentTrack,
    }),
  },
};
</script>
