<template>
  <ul>
    <slot />
  </ul>
</template>

<script>
export default {
  name: 'ListItems',
};
</script>

<style scoped>
ul {
  list-style-type: none;
}
</style>
