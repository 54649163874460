<template>
  <div class="home" v-if="showError">
    <h4>{{ showError }}</h4>
  </div>

  <div class="home" v-else>
    <Category
      v-for="category in categories"
      :key="category"
      :title="category"
      :playlists="playlistCategories[category]"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import Category from '@/components/Category/Category.vue';
import { fetchPlaylists } from '@/api/playlists';

export default {
  name: 'Home',
  props: {},
  data() {
    return {
      isFetching: false,
      showError: '',
      playlists: [],
      categories: [],
      playlistCategories: {},
    };
  },
  computed: {},
  methods: {
    async load() {
      this.isFetching = true;
      try {
        this.playlists = await fetchPlaylists();
      } catch (err) {
        this.showError = 'Sorry, could not show playlists';
      }
      this.isFetching = false;
      this.setCategoriesFromPlaylists(this.playlists);
    },
    setCategoriesFromPlaylists(playlists) {
      this.categories = [];
      playlists.forEach((p) => {
        // check if category is already in this.categories
        if (!this.categories.includes(p.category)) {
          this.categories.push(p.category);
          this.playlistCategories[p.category] = [];
        }
        this.playlistCategories[p.category].push(p);
      });
    },
  },
  components: {
    Category,
  },
  mounted() {
    this.load();
  },
};
</script>
