<template>
  <div class="card-row">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'CardRow',
};
</script>

<style scoped>
.card-row {
  display: flex;
  flex-wrap: wrap;
  border: 0;
}
</style>
