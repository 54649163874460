<template>
  <div class="volume-container">
    <i
      class="fas"
      :class="{ 'fa-volume-up': !isMuted, 'fa-volume-down': isMuted }"
      role="button"
      @click="$emit('toggle-mute')"
    /><input
      :disabled="isMuted"
      type="range"
      min="0"
      :max="maxVal"
      v-model="rangeInputLevel"
      @input="levelChanged"
    />
  </div>
</template>

<script>
export default {
  name: 'VolumeControl',
  props: {
    isMuted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxVal: 10,
      level: 5, // between 0 and 1
      rangeInputLevel: 5,
    };
  },
  methods: {
    levelChanged() {
      this.level = parseInt(this.rangeInputLevel);
      this.$emit('level', this.level / this.maxVal);
    },
    setRangeInputLevel() {
      if (this.isMuted) {
        this.rangeInputLevel = 0;
      } else {
        this.rangeInputLevel = this.level;
      }
    },
  },
  watch: {
    isMuted() {
      this.setRangeInputLevel();
    },
  },
  created() {
    this.setRangeInputLevel();
  },
};
</script>

<style scoped>
.volume-container {
  display: flex;
  align-items: center;
}
.volume-container i {
  width: 20px;
}
.volume-container input {
  margin-left: 0.5em;
  flex: 1 1 auto;
}
</style>
