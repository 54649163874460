<template>
  <div>
    <button @click="setCurrentTrack({})">Set Track</button>
    <button @click="setIsPlaying(!isPlaying)">Toggle Play</button>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'ChangeState',
  computed: {
    isPlaying() {
      return this.$store.state.isPlaying;
    },
  },
  methods: {
    someMethod() {
      this.setCurrentTrack({});
    },
    ...mapMutations(['setCurrentTrack', 'setIsPlaying']),
  },
};
</script>
