import { get } from './helper';

export { fetchPlaylists, fetchPlaylistDetail };

//Modularity purposes

async function fetchPlaylists() {
  return get('/api/playlists.json');
}

async function fetchPlaylistDetail(id) {
  return get(`/api/playlists/${id}.json`);
}
