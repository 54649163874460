<template>
  <div class="SearchBar" @click="quickType">
    <i class="fas fa-search"></i>
    <input
      class="search"
      name="search"
      type="text"
      :placeholder="placeholderText"
      @input="$emit('update:query', $event.target.value)"
      :value="query"
    />
  </div>
</template>

<script>
export default {
  name: 'SearchBar',
  props: {
    query: String,
    placeholder: String,
  },

  methods: {
    quickType() {
      document.getElementById('search').focus();
    },
  },
  computed: {
    placeholderText() {
      return `Search in ${this.placeholder}...`;
    },
  },
};
</script>

<style scoped>
i {
  flex: 1 1 1;
}

i:hover {
  transform: scale(1.1);
  transition: 0.2s linear;
}

input {
  border: 0;
  width: 100%;
  margin-left: 1em;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
}

input:focus {
  outline-width: 0;
}

.SearchBar {
  border: black 1px solid;
  border-radius: 2rem;
  padding: 0.5em;
  display: flex;
  max-height: 1rem;
}
</style>
