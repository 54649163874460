<template>
  <ListItems>
    <ListItem :route="{ name: 'Home' }">
      <template v-slot:image> <img src="@/assets/TJC_Logo.png" /> </template>
    </ListItem>
    <ListItem :route="{ name: 'Home' }" route-text="Home">
      <template v-slot:icon> <i class="fa fa-home" /> </template>
    </ListItem>
    <ListItem :route="{ name: 'Search' }" route-text="Search">
      <template v-slot:icon> <i class="fas fa-search" /> </template>
    </ListItem>
  </ListItems>
</template>

<script>
import ListItem from '@/components/ListItem/ListItem.vue';
import ListItems from '@/components/ListItem/ListItems.vue';
export default {
  name: 'Sidebar',
  components: {
    ListItem,
    ListItems,
  },
};
</script>

<style scoped>
img {
  width: 100%;
}
</style>
