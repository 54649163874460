<template>
  <div id="nav">
    <Sidebar />
  </div>
  <main>
    <router-view />
  </main>
  <div class="playbar">
    <PlaybarContainer />
  </div>
</template>

<script>
import PlaybarContainer from '@/components/PlayBar/PlayBarContainer.vue';
import Sidebar from './components/Sidebar/Sidebar.vue';

export default {
  name: 'App',
  components: {
    Sidebar,
    PlaybarContainer,
  },
};
</script>

<style>
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
}
#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2a3e59;
  display: grid;

  grid-template-areas:
    'sidebar main'
    'playbar playbar';
  grid-template-columns: 1fr 7fr;
  grid-template-rows: 1fr 150px;
  height: 100%;
}

#nav {
  padding: 30px;
  width: 200px;
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
}

a {
  font-weight: bold;
  color: #2a3e59;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

main {
  grid-area: main;
  padding: 1em;
  overflow-y: auto;
}

.playbar {
  grid-area: playbar;
  display: flex;
  align-items: center;
}
</style>
