<template>
  <li
    :class="{ 'side-list-item': $slots.icon, 'side-list-logo': $slots.image }"
  >
    <router-link :to="route">
      <div class="image" v-if="$slots.image">
        <slot name="image" />
      </div>
      <div class="icon-holder" v-if="$slots.icon">
        <slot name="icon" />
      </div>
      {{ routeText }}</router-link
    >
  </li>
</template>

<script>
export default {
  name: 'ListItem',
  props: {
    route: {
      type: Object,
      required: true,
    },
    routeText: String,
  },
};
</script>

<style scoped>
.side-list-logo {
  max-width: 100%;
}

.side-list-logo a {
  display: block;
  width: 100%;
}

.side-list-item a {
  padding: 0.5em 0.5em 0.5em 0.4em;
  margin: 0.3em 0.3em 0.3em 0em;
  display: flex;
  background-color: #bdd9fd;
  border-radius: 0.5rem;
}

.side-list-item .icon-holder {
  margin-right: 0.8rem;
}
</style>
