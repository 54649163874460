<template>
  <Playbar
    v-bind="currentTrack"
    :isTrackPlaying="isPlaying"
    @play-track="setIsPlaying(true)"
    @pause-track="setIsPlaying(false)"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Playbar from './PlayBar.vue';

export default {
  name: 'PlaybarContainer',
  components: {
    Playbar,
  },
  methods: {
    ...mapMutations(['setCurrentTrack', 'setIsPlaying']),
  },
  computed: {
    ...mapState({
      isPlaying: (state) => state.isPlaying,
      currentTrack: (state) => {
        const track = state.currentTrack || {};

        return {
          trackArtist: track.artist,
          trackDuration: track.duration,
          trackTimestamp: '0:00',
          trackTitle: track.title,
          trackUrl: track.url,
          trackId: track.id,
          musicScoreId: track.musicScoreId,
        };
      },
    }),
  },
};
</script>

<style lang="scss" scoped></style>
