import { createStore } from 'vuex';

export default createStore({
  state: {
    currentTrack: null, // contain an object { title, url, etc. }
    isPlaying: false,
  },
  mutations: {
    setCurrentTrack(state, track) {
      state.currentTrack = track;
    },
    setIsPlaying(state, isPlaying) {
      state.isPlaying = isPlaying;
    },
  },
  // getters: {}, // like computed properties but for state
  // actions: {}, // asynchronous functions
  // modules: {},
});
