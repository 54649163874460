<template>
  <div class="card">
    <div class="card-img-container">
      <img v-bind:src="imageUrl" :alt="imageAlt" />
      <button
        v-if="hasPlayButton"
        class="play-btn"
        @click="$emit('playClicked', id)"
      >
        Play
      </button>
    </div>

    <div class="card-title">{{ title }}</div>
    <div class="card-desc">{{ desc }}</div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    id: {
      type: String,
    },
    title: {
      type: String,
    },
    desc: {
      type: String,
    },
    imageUrl: {
      type: String,
      default: 'https://via.placeholder.com/200x250.png',
    },
    imageAlt: {
      type: String,
    },
    hasPlayButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.card {
  padding: 10px 20px;
  width: 200px;
  transition: 0.2s;
}

.card:hover {
  background-color: gray;
}

.card-title {
  font-size: 16px;
  color: black;
}

.card-desc {
  font-size: 12px;
  color: black;
}

.card-img-container {
  position: relative;
}

.card:hover .play-btn {
  display: block;
}

.play-btn {
  background-color: green;
  width: 50px;
  height: 50px;
  position: absolute;
  border-radius: 50%;
  bottom: 12px;
  right: 8px;
  padding: 12px;
  color: white;
  border: 0;
  display: none;
  box-shadow: 0px 5px #888888;
}

.play-btn:hover {
  transform: scale(1.1);
}
</style>
