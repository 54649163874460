<template>
  <div class="track-info">
    <div class="track-img-container">
      <img src="https://via.placeholder.com/50" alt="" />
    </div>
    <div class="track-title">
      <a class="title" href="#">{{ trackTitle }}</a>
      <a class="artist" href="#">{{ trackArtist }}</a>
    </div>
    <button v-if="!!musicScoreId" @click="onViewTrackScore" class="view-score">
      <i class="fas fa-file"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'TrackInfoDisplay',
  props: {
    imageUrl: String,
    playlistId: String,
    trackTitle: String,
    trackArtist: String,
    trackId: String,
    musicScoreId: String,
  },
  data() {
    return {
      playlist: null,
      isFetching: false,
      fetchError: null,
    };
  },
  methods: {
    onViewTrackScore() {
      if (!this.musicScoreId) return;
      this.$router.push({
        name: 'Music score view',
        params: {
          id: this.musicScoreId,
        },
      });
    },
  },
};
</script>

<style scoped>
.track-info {
  display: flex;
  align-items: center;
}
.track-info a {
  display: block;
}
.track-title {
  padding: 1em;
}
.title,
.artist {
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.title:hover,
.artist:hover {
  text-decoration: underline;
}

.view-score {
  border: 0;
  width: 3em;
  height: 3em;
  border-radius: 50%;
}
</style>
