<template>
  <div class="bar" @click="barClicked" ref="barRef">
    <div class="dragger" :style="draggerStyle" @mousedown="startDrag" />
    <div class="progress" :style="progressStyle" />
  </div>
</template>

<script>
export default {
  name: 'Seekbar',
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['drag-start', 'drag-end'],
  data() {
    return {
      dragging: false,
      dragLeftPosition: 0,
      progressWidth: 0,
    };
  },
  computed: {
    draggerStyle() {
      return {
        display: this.disabled ? 'none' : 'block',
        left: `${this.dragLeftPosition * 100}%`,
      };
    },
    progressStyle() {
      return {
        width: `${this.progressWidth * 100}%`,
      };
    },
  },
  methods: {
    barClicked(evt) {
      if (this.disabled) {
        return;
      }
      console.log('clicked');
      this.updateFromMouse(evt);
      this.$emit('drag-end', this.progressWidth); // this gets emitted for click and drag events, since a drag also constitutes a "click"
    },
    startDrag() {
      if (this.disabled) {
        return;
      }
      console.log('start');
      this.$emit('drag-start');

      // we add/remove listeners on the document since the mouse cursor often trails outside the actual bar/dragger elements
      document.addEventListener('mouseup', this.stopDrag);
      document.addEventListener('mousemove', this.doDrag);
      this.dragging = true;
    },
    doDrag(evt) {
      if (this.dragging) {
        console.log('moving');
        this.updateFromMouse(evt);
      }
    },
    stopDrag() {
      console.log('stop');
      document.removeEventListener('mouseup', this.stopDrag);
      document.removeEventListener('mousemove', this.doDrag);
      this.dragging = false;
    },
    getMouseXInBar(evt) {
      const rect = this.$refs.barRef.getBoundingClientRect();
      return evt.clientX - rect.left;
    },
    updateFromMouse(evt) {
      const mouseX = this.getMouseXInBar(evt);
      this.updateUI(mouseX);
    },
    updateFromPercent() {
      this.updateUI(this.percent * this.$refs.barRef.offsetWidth);
    },
    updateUI(xPos) {
      const barWidth = this.$refs.barRef.offsetWidth;
      let newPercent = Math.min(1, Math.max(0, xPos) / barWidth);
      this.progressWidth = newPercent;
      this.dragLeftPosition = newPercent - 0.004;
    },
  },
  watch: {
    percent() {
      this.updateFromPercent();
    },
  },
  mounted() {
    this.updateFromPercent();
  },
};
</script>

<style scoped>
.bar {
  position: relative;
  height: 15px;
  background: #ccc;
  border-radius: 1em 1em;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #bdd9fd;
  border-radius: 1em 1em;
}

.dragger {
  position: absolute;
  top: 0px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #2a3e59;
  z-index: 2;
}
</style>
