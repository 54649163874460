<template>
  <div class="container">
    <TrackInfoDisplay
      :trackTitle="trackTitle"
      :trackArtist="trackArtist"
      :trackId="trackId"
      :musicScoreId="musicScoreId"
    />
    <div class="play-bar">
      <div class="play-bar-buttons">
        <button>
          <i class="fas fa-random"></i>
        </button>
        <button>
          <i class="fas fa-step-backward"></i>
        </button>
        <button @click="$emit(isTrackPlaying ? 'pauseTrack' : 'playTrack')">
          <i
            :class="{
              'fas fa-play': !isTrackPlaying,
              'fas fa-pause': isTrackPlaying,
            }"
          ></i>
        </button>
        <button>
          <i class="fas fa-step-forward"></i>
        </button>
        <button>
          <i class="fas fa-undo"></i>
        </button>
      </div>
      <div class="seekbar-container">
        <span class="timestamp">{{ trackTimestamp }}</span>
        <div class="seekbar-wrapper">
          <Seekbar
            :disabled="!trackTitle"
            :percent="progressPercentage"
            @drag-start="onBeginSeeking"
            @drag-end="onFinishedSeeking"
          />
        </div>
        <span class="timestamp">{{ trackDuration }}</span>
      </div>
    </div>
    <div class="misc-controls">
      <VolumeControl
        :isMuted="isMuted"
        @toggle-mute="isMuted = !isMuted"
        @level="onChangedVolumeLevel"
      />
    </div>
  </div>
</template>

<script>
import Seekbar from '../AudioSandbox/Seekbar.vue';
import VolumeControl from '../Volume/VolumeControl.vue';
import TrackInfoDisplay from '../trackinfodisplay/TrackInfoDisplay.vue';
import {
  percentageDiffOfTimesInSecs,
  timeToSeconds,
  secsToTimestamp,
} from '../../utils';

export default {
  name: 'PlayBar',
  components: {
    TrackInfoDisplay,
    Seekbar,
    VolumeControl,
  },
  props: {
    trackDuration: {
      type: String,
    },
    isTrackPlaying: {
      type: Boolean,
      default: false,
    },
    trackTitle: {
      type: String,
    },
    trackArtist: {
      type: String,
    },
    trackUrl: {
      type: String,
    },
    trackId: {
      type: String,
    },
    musicScoreId: {
      type: String,
    },
  },
  data() {
    return {
      isMuted: false,
      trackTimestampInSecs: 0,
    };
  },
  emits: ['playTrack', 'pauseTrack'],
  computed: {
    progressPercentage() {
      if (!this.trackDuration || !this.trackTimestampInSecs) {
        return 0;
      }
      return (
        percentageDiffOfTimesInSecs(
          this.trackTimestampInSecs,
          timeToSeconds(this.trackDuration),
        ) / 100
      );
    },
    audio() {
      if (this.trackUrl) {
        const audio = new Audio(this.trackUrl);
        audio.addEventListener('timeupdate', this.updateCurrentTime);
        return audio;
      }
      return null;
    },
    trackTimestamp() {
      return secsToTimestamp(Math.floor(this.trackTimestampInSecs));
    },
  },
  methods: {
    updateCurrentTime() {
      if (this.audio) {
        this.trackTimestampInSecs = this.audio.currentTime;
      }
    },
    onBeginSeeking() {
      if (this.audio) {
        this.audio.pause();
      }
    },
    onFinishedSeeking(updatedPercent) {
      if (this.audio) {
        this.audio.currentTime =
          updatedPercent * timeToSeconds(this.trackDuration);
      }
    },
    onChangedVolumeLevel(updatedLevel) {
      if (this.audio) {
        this.audio.volume = updatedLevel;
      }
    },
  },
  watch: {
    isTrackPlaying(isPlaying) {
      if (!this.audio) return;
      if (this.audio.paused && isPlaying) {
        this.audio.play();
      } else {
        this.audio.pause();
      }
    },
    audio(newAudio, oldAudio) {
      // if the audio track changes, pause the exiting one and potentially play the next one
      if (this.isTrackPlaying && oldAudio) {
        oldAudio.pause?.();
        newAudio?.play?.();
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.track-info {
  width: 400px;
  overflow: hidden;
}
.misc-controls {
  width: 200px;
}
.play-bar {
  width: 80%;
}

.timestamp {
  padding: 0em 1em;
}

.play-bar .play-bar-buttons {
  text-align: center;
  margin-bottom: 12px;
}
.play-bar .play-bar-buttons button {
  border: 0;
  width: 3em;
  height: 3em;
  border-radius: 50%;
}
.play-bar .seekbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
}
.play-bar .seekbar-container .seekbar {
  width: 80%;
  height: 5px;
  background: grey;
  position: relative;
}

.play-bar .seekbar-container .seekbar:hover .seek-ball {
  display: block;
}

.play-bar .seekbar-container .seekbar .progress-bar {
  height: 100%;
  background: black;
}

.seekbar-wrapper {
  flex: 1 1 auto;
}
</style>
